import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ title, meta }) {
	return (
		<StaticQuery
			query={detailsQuery}
			render={(data) => {
				const metaDescription = (data.site && data.site.description) || '';
				const siteTitle = title || (data.site && data.site.title) || '';
				const keywords = data.site && data.site.keywords;
				// console.log('SEO data.site: ', data.site)
				return (
					<Helmet
						htmlAttributes={{ lang: 'en' }}
						title={title}
						titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
						meta={[
							{
								name: 'description',
								content: metaDescription,
							},
							{
								property: 'og:title',
								content: title,
							},
							{
								property: 'og:description',
								content: metaDescription,
							},
							{
								property: 'og:type',
								content: 'website',
							},
							{
								property: 'author',
								content: 'Loopspeed Ltd',
							},
						]
							.concat(
								keywords && keywords.length > 0
									? {
											name: 'keywords',
											content: keywords.join(', '),
									  }
									: []
							)
							.concat(meta)}
					/>
				);
			}}
		/>
	);
}

SEO.defaultProps = {
	lang: 'en',
	meta: [],
	keywords: [],
};

SEO.propTypes = {
	meta: PropTypes.array,
	title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
			title
			description
			keywords
		}
	}
`;
